import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';

import {BrowserRouter} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import Root from './components/root';
import ScrollToTop from './components/scrolltotop';
import reducer from './components/reducer';

const store = createStore(reducer);

ReactDOM.render(
   <Provider store={store}>
      <BrowserRouter>
         <ScrollToTop />
         <Root />
      </BrowserRouter>
   </Provider>,
   document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
